<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'posItemEdit', action: null })" :extraLarge="!!(item && item.id)" :showNavigationRight="!!editAction?.next" @goRight="editAction.next"
    :size="!editAction.id ? 'md' : 'lg'" :hideFooter="!showFooter" class="pos-item-editor" @goLeft="editAction.previous" :showNavigationLeft="!!editAction?.previous">
    <template v-slot:modal-title>
      <b-row>
        <b-col cols="2" class="px-0" v-if="item">
          <div class="position-relative images-controls-wrapper">
            <imageView :src="item.image || '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png'" @click="editPOSItemImage" filterDisplay="tinyThumb" />
            <div class="position-absolute" v-if="item.image" style="bottom: 0; right: 25px;">
              <b-button variant="link" @click="removePOSItemImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericRemove">
                <icon name="wisk-trash" class="image-view-overlay-icon text-danger" :scale="1" />
              </b-button>
            </div>
            <div class="position-absolute" style="bottom: 0; right: 0;">
              <b-button variant="link" @click="editPOSItemImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericEdit">
                <icon name="wisk-edit" class="image-view-overlay-icon text-primary" :scale="1" />
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="10" :class="{ 'bg-warning': !!(item && item.archived) }" :style="{ 'background-color': item.is_draft_pos_item ? 'rgba(var(--bs-warning-rgb), .5)' : undefined }">
          <h5 class="modal-title mb-2">
            {{ modalTitle }} {{ item.is_draft_pos_item ? `(${translations.txtPOSItemDraftPOSItem})` : '' }}
          </h5>
        </b-col>
      </b-row>
    </template>
    <template v-slot:modal-header-extra>
      <b-row v-if="item?.id">
        <b-col cols="8" class="text-center">
          <b-form-radio-group size="sm" style="margin-left: 5px;" buttons v-model="selectedEditorTab" name="selectedEditorTab" button-variant="outline-primary" class="mb-2">
            <b-form-radio :value="0"> {{ translations.txtPOSItemEdit }} </b-form-radio>
            <b-form-radio v-if="editAction.id" :value="1"> {{ translations.txtGenericTimeline }} </b-form-radio>
            <b-form-radio v-if="editAction.id" :value="2"> {{ translations.txtGenericSales }} </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col cols="4">
          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" />
        </b-col>
      </b-row>
    </template>

    <div v-if="item" :key="posItemKey">
      <div v-if="selectedEditorTab === 0" :style="{ 'height': !editAction.id ? 'auto' : 'calc(100vh - 260px)' }">
        <wiskInputGroup @errorCountChanged="setValidState" :loading="editorLoading" v-if="!editAction.id" :disabled="item && item.archived" @operationsChange="onChangeNewPOSItem">

          <b-row>
            <b-col cols="12">
              <wiskInput infoTooltipKey="8e669ecd-3b08-4426-bf79-613d187d9376" :label="translations.txtGenericTitle" :modelValue="item.title" required operation="title" />
            </b-col>
            <b-col v-if="!editAction.draftPOSItem" cols="12">
              <wiskInput infoTooltipKey="698d45d4-1b96-4432-abe4-168826e263b3" :label="translations.txtGenericPOSCode" :modelValue="item.plu_number" operation="plu_number" />
            </b-col>
          </b-row>
        </wiskInputGroup>
        <wiskInputGroup class="mx-0-m px-0-m" @errorCountChanged="setValidState" :loading="editorLoading" v-if="editAction.id" :disabled="(item && item.archived) || !currentPermissionsByType.pos_item_manage">
          <b-row class="pb-4 align-items-center">
            <b-col v-if="!item.editable" class="flex-grow-1">
              <h6 class="m-0">
                {{ translations.txtPOSItemEditNotEditableMessage }}
              </h6>
            </b-col>
            <div class="w-auto">
              <wiskInput infoTooltipKey="0c4550e9-00f6-4c1f-a99f-8bc2b06eec2c" class="m-0" :label="translations.txtPOSItemShowIngredients" v-model="showAllIngredients" inputType="checkbox" />
            </div>
          </b-row>
          <b-row>
            <b-col lg="3" md="6" cols="12" class="mb-2-m">
              <wiskInput infoTooltipKey="206e73a8-d4bd-43f4-8d56-13dc8d20477c" :label="translations.txtGenericTitle" :disabled="!item.editable" :modelValue="item.title" required
                operation="title" @operation="onChange" class="mb-2" />
            </b-col>
            <b-col lg="3" md="6" cols="12" class="mb-2-m">
              <wiskInput infoTooltipKey="539d8fc3-e302-45ac-a5e6-c8057c52404e" :label="translations.txtGenericPOSCode" :disabled="!item.editable" :modelValue="item.plu_number"
                operation="plu_number" @operation="onChange" required class="mb-2" />
            </b-col>
            <b-col lg="2" md="4" cols="12" class="mb-2-m">
              <wiskInput infoTooltipKey="2932dedf-d16a-46b7-8e3c-7534288c41ce" :label="translations.txtGenericPrice" :disabled="!item.editable" :decimals="4" decimalsAsNeeded :minDecimals="2" :modelValue="item.price"
                inputType="number" operation="price" @operation="onChange" class="mb-2" />
            </b-col>
            <b-col lg="2" md="4" cols="12" class="mb-2-m">
              <wiskInput infoTooltipKey="6d4dd4b4-0303-4ab9-8653-179001149e17" :validations="[extraCostValidation]" :label="translations.txtPOSItemEditNonBeverageCost"
                :modelValue="item.non_beverage_cost" :decimals="4" decimalsAsNeeded :minDecimals="2"
                inputType="number" operation="non_beverage_cost" @operation="onChange" class="mb-2" />
            </b-col>
            <b-col lg="2" v-if="item && item.stats" md="4" cols="12" class="mb-2-m mb-3 mb-md-0">
              <div :class="{ attention: !item.cost_alert_percentage }" style="border-radius: 4px; height: 33px;">
                <wiskInput infoTooltipKey="f3d57c5b-6322-46a7-bcab-002fe1146034" :label="translations.txtPOSItemsCostAlert" :transformerOut="z => z / 100" :transformerIn="z => z * 100"
                  :modelValue="item.cost_alert_percentage" :decimals="2" inputType="number" operation="cost_alert_percentage" @operation="onChange" suffix="%" class="mb-2" />
              </div>
            </b-col>
            <b-col v-if="item.is_draft_pos_item" lg="2" md="4" cols="12" class="d-flex align-items-center mb-2 offset-lg-6">
              <wiskInput infoTooltipKey="964bdbb3-ebac-4d29-922b-479f43aabe59" :label="translations.txtDashboardOverviewIdealCost" :transformerOut="z => z / 100" :transformerIn="z => z * 100"
                         :modelValue="item.target_cost" :decimals="2" inputType="number" operation="target_cost" @operation="onChange" suffix="%" class="flex-grow-1 mb-0" />
              <b-button variant="link" class="px-2" @click="onChange({ type: 'target_cost', value: item.target_cost })" title="Recalculate">
                <icon class="text-primary" name="wisk-redo" :scale="0.7"></icon>
              </b-button>
            </b-col>
          </b-row>

          <b-alert v-if="item.needs_review && item.previous_title" class="m0" variant="warning" show @closed="onChange({ type: 'needs_review', value: false })" dismissible>
            {{ translations.translate('tplPOSItemsNeedReviewTitleChanged', { '{a}': item.previous_title, '{b}': item.title }) }}
            <infoTooltip :scale=".8" helpKey="posItemNeedReviewTitleChanged" />
          </b-alert>

          <wiskItemsGrid class="p- mb-4 " gridAutoHeight :columns="itemsGridColumns" :items="item.ingredients" trackBy="gridId" v-if="mounted" :additionalHeaderCols="6"
            :defaultFilter="{ predicate: () => 1 }" :key="gridKey" parentGridName="POSItemIngredients" :header="{ hideSearch: true }" :selectedRowsActionsOverride="selectedRowsActions">
            <template v-slot:additional-controls>
              <b-row class="mb-3 fs-5 " style="flex: 1">
                <b-col v-if="item && item.stats" md="2" cols="12" class="mb-2-m text-md-start text-start">
                  <span> {{ translations.txtGenericPrice }}: </span>
                  <span class="text-bold1"> {{ currencyFormat(item.price) }}</span>
                </b-col>
                <b-col v-if="item && item.stats" md="2" cols="12" class="mb-2-m  text-md-center text-start">
                  <span> {{ translations.txtGenericCost }}: </span>
                  <span class="text-bold1" :class="costDisplayColor"> {{ costFormatted }}</span>
                </b-col>
                <b-col v-if="item && item.stats" md="2" cols="12" class="mb-2-m text-md-center text-start">
                  <span> {{ translations.txtGenericProfit }}: </span>
                  <span class="text-bold1" :class="profitDisplayColor"> {{ profitFormatted }}</span>
                </b-col>
                <b-col v-if="item && item.stats" md="3" cols="12" class="mb-2-m text-md-end text-start">
                  <span> {{ translations.txtGenericCostPercentage }}: </span>
                  <span class="text-bold1" :class="costDisplayColor"> {{ costPercentageFormatted }}</span>
                  <v-tooltip class="float-end ms-1">
                    <icon class="info-icon text-secondary" name="wisk-information-button" :scale=".8"></icon>
                    <template #popper>
                      <span v-if="item.cost_alert_percentage">
                        {{ costDisplayColor === 'text-danger' ? translations.txtPOSItemCostMoreThanAlert : translations.txtPOSItemCostLessThanAlert }}
                      </span>
                      <span v-else>
                        {{ translations.txtPOSItemCostAlertEmpty }}
                      </span>
                    </template>
                  </v-tooltip>
                </b-col>
                <b-col v-if="item && item.stats" md="3" cols="12" class="mb-2-m  text-md-center text-start">
                  <span> {{ translations.txtPOSItemProfitPercentage }}: </span>
                  <span class="text-bold1" :class="profitDisplayColor"> {{ profitPercentageFormatted }}</span>
                </b-col>
              </b-row>
            </template>
          </wiskItemsGrid>
          <!-- reactToChangesFast -->
          <addIngredient v-if="item" @add="addIngredient" :disabled="!currentPermissionsByType.pos_item_manage || item.archived" />

          <customFields v-if="showCustomFieldsGroup" target="pos_item" :item="item" @operation="onChange" mdCol="3" />
          <wiskInput infoTooltipKey="9bad95bf-0ea7-445b-af22-d0e90f636c7b" :valueCol="10" :labelCol="2" :label="translations.txtGenericNotes" multiline :modelValue="item.notes" inputType="text"
            operation="notes" @operation="onChange" class="pb-3" />
        </wiskInputGroup>
      </div>
      <div v-if="selectedEditorTab === 1" style="">
        <timeline v-if="item" :filters="[{ type: 'pos_item', id: item.id }]" parentGridName="POSItemEditTimeline" :gridStyle="{ height: 'calc(100vh - 302px)' }" />
      </div>
      <div v-if="selectedEditorTab === 2" style="height: calc(100vh - 280px); overflow: auto;">
        <posItemsSales v-if="item" :posItem="item" :editAction="editAction" />
      </div>
    </div>
    <measurement v-if="selectedCustomServingSizeIngredient" :modelValue="selectedCustomServingSizeIngredient.serving_size.measurement"
      disableTypeChange @update:modelValue="saveCustomServingSize" startOpened @hide="selectedCustomServingSizeIngredient = null" :preferredType="selectedCustomServingSizeIngredient?.measurement?.type"
      :extraUnitsOfMeasurement="[{ id: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]" />

    <confirm ref="confirmDialog" />

    <template v-slot:modal-footer>
      <editorButtons :editorValid="valid" :save="(editAction && !editAction.id && saveNew) || undefined" />
    </template>
  </wiskModal>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import merge from 'lodash.merge'
import isEqual from 'lodash.isequal'
import get from 'lodash.get'
import api from '@/api'
import { preparePOSItem, guid, percentageFormat, currencyFormat } from '@/modules/utils'
import posItemsSales from '@/components/posItems/POSItemSales'
import timeline from '@/components/timeline/Timeline'
import editorButtons from '@/components/common/WiskEditorModalButtons'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import wiskInput from '@/components/common/WiskInput'
import addIngredient from '@/components/common/IngredientAdd'
import measurement from '@/components/bottles/Measurement'
import customFields from '@/components/customFields/CustomFieldsRender'
import imageView from '@/components/common/ImageView'
import { getItemsGridColumns } from './columnDefs'

const getPOSItemBase = () => ({ id: 0, ingredients: [], title: '', suggested_price_stats: {} })

export default {
  name: 'POSItemEdit',
  emits: ['valid'],
  components: { imageView, addIngredient, timeline, editorButtons, wiskItemsGrid, posItemsSales, wiskInput, measurement, customFields },
  props: {
    editAction: {
      type: Object
    }
  },
  data() {
    return {
      valid: true,
      selectedEditorTab: 0,
      editorLoading: false,
      item: null,
      selectedCustomServingSizeIngredient: null,
      ingredients: [],
      price: 0,
      operations: [],
      itemsGridColumns: {},
      gridKey: 1,
      posItemKey: 1,
      preparePOSItemDebounceId: null,
      onDemandStateIdPOSItem: guid(),
      onDemandStateIdPOSItemCost: guid(),
      mounted: false,
      showAllIngredients: false
    }
  },
  computed: {
    ...mapGetters(['venue']),
    ...mapState(['firestoreInitialLoadComplete', 'posItemsById', 'customFieldsByTarget', 'posItemCostsById', 'translations', 'servingSizes', 'bottlesById', 'allergens', 'subrecipesById', 'currentPermissionsByType']),
    profitFormatted() {
      return currencyFormat(get(this.item, 'stats.cost.profit', 0))
    },
    profitDisplayColor() {
      return get(this.item, 'stats.cost.profit', 0) > 0 ? 'text-success' : 'text-danger'
    },
    costDisplayColor() {
      if (this.item.cost_alert_percentage) {
        return get(this.item, 'stats.cost.percentage', 0) > this.item.cost_alert_percentage ? 'text-danger' : 'text-success'
      }
      return ''
    },
    costFormatted() {
      return currencyFormat(get(this.item, 'stats.cost.dollars', 0))
    },
    costPercentageFormatted() {
      return percentageFormat(get(this.item, 'stats.cost.percentage', 0))
    },
    profitPercentageFormatted() {
      return percentageFormat(1 - get(this.item, 'stats.cost.percentage', 0))
    },
    showCustomFieldsGroup() {
      if (this.customFieldsByTarget && this.customFieldsByTarget.pos_item && this.customFieldsByTarget.pos_item.length) {
        let active = this.customFieldsByTarget.pos_item.filter(f => !f.archived)
        return !!active.length
      }

      return false
    },
    selectedRowsActions() {
      let actions = []
      if (this.currentPermissionsByType.pos_item_manage && !this.showAllIngredients) {
        actions.push({
          key: 'ingredient_delete',
          title: this.translations.txtGenericRemove,
          variant: 'outline-danger',
          type: 'button',
          action: (rows) => {
            rows.forEach(row => {
              //if cloudflare will complain about too many requests, we can switch to batch operation or a for of loop
              this.updateValue({
                type: 'ingredient_delete',
                value: {
                  ingredient: row.operation
                }
              })
            })
          }
        })
      }
      return actions
    },
    pageActions() {
      return [
        { key: 9, title: this.translations.txtSubrecipeGetRecipePDF, icon: 'wisk-book', variant: 'primary', action: this.emailRecipePDF },
        { key: 10, title: this.translations.txtPOSItemsServingSizes, icon: 'wisk-whiskey-glass', variant: 'primary', action: this.openServingSizes },
        { key: 12, title: this.translations.txtCustomFieldAdd, icon: 'wisk-plus', variant: 'primary', action: this.openAddCustomField },
        { key: 11, title: this.translations.txtCustomFieldsView, icon: 'wisk-options', variant: 'primary', action: this.openCustomFields },
        {
          key: 15,
          title: this.translations.txtPOSItemCloneAsDraft,
          icon: 'wisk-tasks',
          variant: 'primary',
          // hide: this.item.is_draft_pos_item,
          action: this.cloneAsDraftPOSItem
        },
        { key: 20, isDivider: true, hide: !this.currentPermissionsByType.pos_item_manage },
        {
          key: 30,
          title: this.translations.txtGenericArchive,
          icon: 'wisk-archive',
          variant: 'danger',
          hide: !this.currentPermissionsByType.pos_item_manage || this.item.archived,
          action: this.toggleArchived
        },
        {
          key: 40,
          title: this.translations.txtGenericRestore,
          icon: 'wisk-history',
          variant: 'success',
          hide: !this.currentPermissionsByType.pos_item_manage || !this.item.archived,
          action: this.toggleArchived
        }
      ]
    },
    showFooter() {
      return this.editAction && !this.editAction.id
    },
    itemsUsedByPOSItem() {
      return (this.item && this.item.ingredients.filter(ingredient => ingredient.item_id).map(ingredient => this.bottlesById[ingredient.item_id])) || []
    },
    subrecipesUsedByPOSItem() {
      return (this.item && this.item.ingredients.filter(ingredient => ingredient.subrecipe_id).map(ingredient => this.subrecipesById[ingredient.subrecipe_id])) || []
    },
    posItem() {
      return (this.item && this.posItemsById[this.item.id]) || null
    },
    posItemCost() {
      return (this.item && this.posItemCostsById[this.item.id]) || null
    },
    modalTitle() {
      if (this.item && this.item.title) {
        let title = this.item.title
        if (this.item.archived) {
          title += ` - (${this.translations.txtGenericArchived})`
        }
        return title
      }
      return this.editAction.id ? 'Loading...' : this.editAction.draftPOSItem ? this.translations.txtPOSItemNewDraftPOSItem : this.translations.txtPOSItemsNewPOSItem
    },
    extraCostValidation() {
      return {
        validator: value => this.item && ((this.item.price && value <= this.item.price) || !this.item.price),
        message: this.translations.translate('tplValidationTooHigh', {
          '{a}': this.translations.txtPOSItemEditNonBeverageCost,
          '{b}': this.translations.txtGenericPrice
        })
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setPOSItem', 'setOnDemandState']),
    currencyFormat,
    cloneAsDraftPOSItem() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            api.clonePOSItemAsDraft(this.item.id).then((response) => {
              this.setGlobalAction({ type: 'posItemEdit', action: { id: response.id }, resetBeforeOpen: true })
            })
          },
          message: this.translations.txtPOSItemCloneAsDraftConfirmationMessage,
          title: this.translations.txtPOSItemCloneAsDraft
        })
      }
    },
    openServingSizes() {
      this.setGlobalAction({ type: 'servingSizes', action: {} })
    },
    emailRecipePDF() {
      if (this.item && this.item.id) {
        api.emailPdf('recipe-book/pdf',
          // `${this.translations.translate('tplSubrecipeRecipePDFFor', { '{a}': this.item.title })}.pdf`,
          {
            pos_item_ids: [this.item.id]
          })
      }
    },
    editPOSItemImage() {
      this.setGlobalAction({
        type: 'fileUpload',
        action: {
          fileTypesAccept: 'image/*',
          useGoogle: true,
          target: this.item,
          save: value => {
            this.updateValue({ type: 'image', value })
          }
        }
      })
    },
    removePOSItemImage() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          message: this.translations.confirmRemoveImages,
          title: this.translations.confirmRemoveTitle,
          callback: () => {
            this.updateValue({ type: 'image_clear' })
          },
        })
      }
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'pos_item' } })
    },
    openAddCustomField() {
      this.setGlobalAction({ type: 'customFieldEdit', action: { target: 'pos_item' } })
    },
    setValidState(errorCount) {
      this.valid = !errorCount
      this.$emit('valid', this.valid)
    },
    addIngredient(operation) {
      this.updateValue(operation)
    },
    onChange(operation) {
      this.updateValue([operation])
    },
    onChangeNewPOSItem(operations) {
      this.operations = operations
    },
    saveNew() {
      if (this.valid) {
        if (!this.operations.find(operation => operation.type === 'plu_number')) {
          this.operations.push({ type: 'plu_number', value: guid() })
        }
        if (this.editAction.draftPOSItem) {
          this.operations.push({ type: 'draft_pos_item', value: true })
        }
        this.updateValue(this.operations).then(newPosItem => {
          this.setGlobalAction({ type: 'posItemEdit', action: null })

          setTimeout(() => {
            if (newPosItem) {
              this.setGlobalAction({ type: 'posItemEdit', action: { id: newPosItem.id } })
            }
          }, 0)
        })
      }
    },
    openCustomServingSize(id) {
      let found = this.item.ingredients.find(i => i.id === id)
      this.selectedCustomServingSizeIngredient = found
    },
    saveCustomServingSize(newMeasurement) {
      let servingSize = merge({}, this.selectedCustomServingSizeIngredient.serving_size),
        ingredient = merge({}, this.selectedCustomServingSizeIngredient.operation),
        from = { ingredient, serving_size: this.selectedCustomServingSizeIngredient.serving_size }

      delete servingSize.quantity
      delete servingSize.unit_of_measurement
      delete servingSize.id

      servingSize.measurement = newMeasurement
      servingSize.type = 'custom'

      let operation = {
        type: 'ingredient_update',
        value: {
          ingredient,
          serving_size: servingSize
        },
        from
      }

      this.updateValue(operation)
      this.selectedCustomServingSizeIngredient = null
    },
    updateValue(operation) {
      return new Promise((resolve, reject) => {
        if (this.item) {
          let operations = Array.isArray(operation) ? operation : [operation]

          this.$store.commit('mutatePOSItem', this.item)

          const targetCost = operations.find(o => o.type === 'target_cost')
          if (targetCost) {
            operations.push({
              type: 'price',
              value: +(get(this.item, 'stats.cost.dollars', 0) / targetCost.value).toFixed(2)
            })
          }

          this.setPOSItem({ id: this.item.id, operation: operations })
            .then(result => {
              if (this.editAction.onChange) {
                this.editAction.onChange()
              }
              resolve(result)
            })
            .catch(() => {
              reject()
            })
        } else {
          reject()
        }
      })
    },
    toggleArchived() {
      if (this.$refs.confirmDialog && this.item) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.updateValue({
              type: 'archive',
              value: !this.item.archived
            })
          },
          message: this.item.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.item.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    preparePOSItem(timeout = 600) {
      clearTimeout(this.preparePOSItemDebounceId)

      this.preparePOSItemDebounceId = setTimeout(() => {
        if (this.editAction && this.editAction.id) {
          if (this.posItemsById[this.editAction.id]) {
            const temp = { ...getPOSItemBase(), ...preparePOSItem(this.posItemsById[this.editAction.id], this.$store.state, this.showAllIngredients) }
            temp.ingredients.sort((a, b) => get(b, 'stats.cost.dollars', 0) - get(a, 'stats.cost.dollars', 0))
            this.item = temp
            this.prepareColumns()
            console.log('this.item', JSON.parse(JSON.stringify(this.item)))
          } else {
            this.preparePOSItem()
          }
        }
      }, timeout)
    },
    prepareColumns() {
      this.itemsGridColumns = getItemsGridColumns({
        translations: this.translations,
        servingSizes: this.servingSizes,
        openCustomServingSize: this.openCustomServingSize,
        save: this.updateValue,
        disabled: !!this.item.archived,
        allergens: this.venue.venue_type === 'food' ? this.allergens : null,
        ingredients: this.item?.ingredients,
        showAllIngredients: this.showAllIngredients
      })
    }
  },
  mounted() {
    this.preparePOSItem(this.posItemsById[this.editAction.id] ? 0 : 600)

    setTimeout(() => {
      let element = document.getElementById('pos-item-add-ingredient-autocomplete')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
      this.selectedEditorTab = (this.editAction && this.editAction.selectedEditorTab) || 0
    }, 0)

    setTimeout(() => {
      this.mounted = true
    }, 1000)
  },
  beforeUnmount() {
    clearTimeout(this.preparePOSItemDebounceId)
    // this.setOnDemandState({ id: this.onDemandStateIdPOSItem, type: 'pos_item', REMOVE: true })
    // this.setOnDemandState({ id: this.onDemandStateIdPOSItemCost, type: 'pos_item_cost', REMOVE: true })
  },
  watch: {
    editAction: {
      immediate: true,
      deep: true,
      handler(editAction) {
        if (editAction) {
          this.item = { ...getPOSItemBase() }
        } else {
          this.item = null
        }
      }
    },
    firestoreInitialLoadComplete: 'preparePOSItem',
    posItem: 'preparePOSItem',
    posItemCost(newValue, previous) {
      if (!isEqual(newValue, previous)) {
        this.preparePOSItem()
      }
    },
    itemsUsedByPOSItem(newValue, previous) {
      if (!isEqual(newValue, previous)) {
        this.preparePOSItem()
      }
    },
    subrecipesUsedByPOSItem(newValue, previous) {
      if (!isEqual(newValue, previous)) {
        this.preparePOSItem()
      }
    },
    'customFieldsByTarget.pos_item': 'prepareColumns',
    showAllIngredients() {
      this.preparePOSItem()
    }
  }
}
</script>

<style lang="scss">
.pos-item-editor {
  .modal-header {
    padding-bottom: 0;
  }
}
</style>
