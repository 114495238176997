<template>
  <wiskGrid :rowData="rowData" :columnDefs="columns" parentGridName="itemUsageGrid" gridAutoHeight @selectedRowsChanged="$emit('selectedRowsChanged', $event)" @gridApi="gridReady" />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import get from 'lodash.get'
import wiskGrid from '@/components/grids/WiskGrid'

export default {
  name: 'ItemUsageGrid',
  emits: ['selectedRowsChanged'],
  components: { wiskGrid },
  props: {
    item: { type: Object, required: true },
    selectable: { type: Boolean, default: false }
  },
  data() {
    return { gridApi: null }
  },
  computed: {
    ...mapState(['posItemsById', 'translations', 'subrecipesById']),
    columns() {
      return [
        {
          colId: 'checkboxSelection',
          sortOrder: 200,
          headerName: '',
          labelForPanel: this.translations.txtGenericSelection,
          headerClass: ['checkbox-cell text-center'],
          minWidth: 30,
          width: 40,
          maxWidth: 50,
          cellClass: ['checkbox-cell'],
          checkboxSelection: true,
          hide: false,
          remove: !this.selectable,
          cellRendererParams: {
            excludeFromExport: true
          },
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true
        },
        {
          colId: 'title',
          headerName: this.translations.txtGenericTitle,
          field: 'title'
        },
        {
          colId: 'type',
          headerName: this.translations.txtGenericType,
          field: 'typeLabel'
        },
        {
          colId: 'more',
          sortOrder: 2800,
          headerName: this.translations.columnDetails,
          minWidth: 100,
          width: 100,
          cellClass: ['cell-more-details'],
          cellRenderer: 'CellMoreDetails',
          suppressColumnsToolPanel: true,
          suppressSortingToolPanel: true,
          suppressFiltersToolPanel: true,
          cellRendererParams: {
            excludeFromExport: true,
            translate: this.translations.translate,
            onClick: (id, cellValue) => {
              let type = get(cellValue, 'data.editorType')
              this.setGlobalAction({ type, action: { id } })
            }
          },
          valueGetter: params => ({
            id: params.data && (params.data.item_id || params.data.id),
            group: !!params.node.group,
            data: params.data
          })
        }
      ]
    },
    rowData() {
      let arr = []

      if (this.item && this.item.used_by) {
        let posItems = get(this.item, 'used_by.pos_items', []),
          subrecipes = get(this.item, 'used_by.subrecipes', [])

        posItems.forEach(id => {
          let posItem = this.posItemsById[id]
          if (posItem) {
            arr.push({ ...posItem, type: 'pos_item', editorType: 'posItemEdit', typeLabel: this.translations.txtGenericPOSItem })
          }
        })
        subrecipes.forEach(id => {
          let subrecipe = this.subrecipesById[id]
          if (subrecipe) {
            arr.push({ ...subrecipe, type: 'subrecipe', editorType: 'subrecipeEdit', typeLabel: this.translations.txtSubrecipeTitle })
          }
        })
      }
      return arr
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    gridReady(agGridApi) {
      this.gridApi = agGridApi

      setTimeout(() => {
        if (this.selectable) {
          this.gridApi.selectAllFiltered()
        }
      }, 600)
    }
  }
}
</script>

<style lang="scss"></style>
