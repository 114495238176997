<template>
  <wiskModal :visible="!!editAction" @hide="setGlobalAction({ type: 'independentInventoryEdit', action: null })" extraLarge :title="modalTitle" size="lg"
    :warning="inventory && inventory.archived" fullHeight hideFooter hideHeaderExtra>
    <template v-slot:modal-title>
      <b-row>
        <b-col md="4" cols="6">
          <h5 class="modal-title me-2 float-start" style="">{{ modalTitle }}</h5>
        </b-col>
        <b-col lg="2" md="3" cols="6">
          <b-form-radio-group size="sm" class="float-end" buttons v-model="viewMode" name="viewMode" button-variant="outline-primary">
            <b-form-radio value="edit"> {{ translations.txtGenericEdit }} </b-form-radio>
            <b-form-radio value="item"> {{ translations.txtGenericDetails }} </b-form-radio>
            <b-form-radio value="consumption"> {{ translations.txtGenericConsumption }} </b-form-radio>
            <b-form-radio v-if="!variance?.result?.no_previous_inventory" value="variance"> {{ translations.txtGenericVariance }} </b-form-radio>
            <b-form-radio value="timeline"> {{ translations.txtGenericTimeline }} </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col lg="4" md="3" cols="6">
          <dateRangeSelector :shortcuts="false" class="float-start" v-model="inventoryDuration" @update:modelValue="updateInventoryDuration" :missingEndLabel="translations.txtInventoriesFinishedAtNone" />
        </b-col>
        <b-col lg="2" md="2" cols="6">
          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" class="float-end ms-2" />
          <b-button variant="primary" class="float-end py-1" @click="save({ type: 'submit' })" size="sm" v-if="inventory && !inventory.finished_at">
            {{ translations.txtGenericSubmit }}
          </b-button>
        </b-col>
      </b-row>
    </template>

    <template v-if="inventoryData && inventory && inventory.id">
      <div v-show="viewMode === 'edit'">
        <div class="d-flex flex-column" style="height: calc(100vh - 160px);">
          <pairEdit class="flex-grow-1" :inventoryData="inventoryData" :inventoryId="inventory.id" :locationId="locationId" :entries="entries" :key="key" @operation="save" @itemOperation="saveItem" />
          <customFields class="mb-1" target="independent_inventory" :item="inventoryData.current" @operation="save" />
        </div>
      </div>

      <wiskItemsGrid v-show="viewMode === 'item' && columns && gridOptions" class="p-0" :gridStyle="{ height: 'calc(100vh - 270px)' }" :columns="columns" :items="visibleItems" :header="{}" :options="gridOptions" trackBy="combined_id" :additionalHeaderCols="7" :additionalHeaderColsMobile="10" :searchLabel="translations.txtGenericTypeToSearch" :excel="{ fileName: 'InventoryItems' }" :parentGridName="`InventoryItems_${viewMode}`" :defaultFilter="{ predicate: item => !!item, name: 'all', label: 'All' }" :loading="loading">

        <template v-slot:additional-controls>
          <div class="align-self-start" style="min-width: 200px">
            <b-button size="sm" class="align-self-start" variant="link" @click="getInventory"> {{ translations.txtWiskGridRefreshData }} </b-button>
          </div>
          <div class="align-self-start" style="min-width: 200px">
            <wiskInput infoTooltipKey="0fa997e1-33cd-4beb-a325-f9cefd853d9e" class="m-0" readonly showPlainText inputType="number" :decimals="2" :prefix="currency" :modelValue="total"
              :label="translations.txtGenericTotal" defaultIfEmpty="0" />
          </div>
        </template>
      </wiskItemsGrid>

      <consumptionGrid v-if="selectedRange" v-show="viewMode === 'consumption'" :selectedRange="selectedRange" independentInventory :parentGridName="`ConsumptionGrid_item`" />

      <varianceGrid v-if="selectedRange" v-show="viewMode === 'variance'" :loading="varianceLoadingOverlay" :items="varianceItems" :selectedRange="selectedRange" independentInventory />

      <timeline v-if="viewMode === 'timeline'" :filters="[{ type: 'independent_inventory', id: editAction.id }]" parentGridName="independentInventoryEditTimeline" gridAutoHeight />
    </template>

    <confirm ref="confirmDialog" autofocus />
    <wiskLoading :loading="!(inventoryData && inventory && inventory.id)" />
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import get from 'lodash.get'
import api from '@/api'
import { compareNumbers } from '@/modules/utils'
import pairEdit from '@/components/inventories/IndependentInventoryPair'
import dateRangeSelector from '@/components/common/DateRangeSelector'
import customFields from '@/components/customFields/CustomFieldsRender'
import { getItemsGridColumns } from '@/components/inventories/gridOptions'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import consumptionGrid from '@/components/consumption/ConsumptionGrid'
import varianceGrid from '@/components/variance/VarianceGrid'
import timeline from '@/components/timeline/Timeline'

export default {
  name: 'IndependentInventoryEdit',
  components: { timeline, pairEdit, dateRangeSelector, customFields, wiskItemsGrid, consumptionGrid, varianceGrid },
  props: {
    editAction: { type: Object }
  },
  data() {
    return {
      inventory: null,
      inventoryData: null,
      inventoryDuration: [],
      locationId: null,
      entries: [],
      loading: false,
      key: 1,
      viewMode: 'edit',
      items: [],
      variance: {},
      varianceItems: [],
      varianceLoadingOverlay: false,
      selectedRange: null
    }
  },
  computed: {
    ...mapState(['translations', 'bottlesById', 'itemVariantsById', 'currentPermissionsByType']),
    ...mapGetters(['currency']),
    pageActions() {
      let actions = []

      if (this.inventory?.id) {
        actions.push( { key: 50, title: this.translations.txtInventoriesEmailReports, action: this.emailReports, variant: 'primary', icon: 'wisk-reports-analytics' } )

        if (this.currentPermissionsByType.inventory_manage) {
          actions.push(
            { key: 84, title: this.translations.txtCustomFieldAdd, variant: 'primary', icon: 'wisk-plus', action: this.openAddCustomField },
            { key: 85, title: this.translations.txtCustomFieldsView, icon: 'wisk-options', variant: 'primary', action: this.openCustomFields },
          )

          actions.push(
            { key: 6, isDivider: true },
            { key: 7, title: this.translations.txtGenericArchive, icon: 'wisk-archive', variant: 'danger', hide: this.inventory.archived, action: this.toggleArchive },
            { key: 8, title: this.translations.txtGenericRestore, icon: 'wisk-history', variant: 'success', hide: !this.inventory.archived, action: this.toggleArchive }
          )
        }
      }
      return actions
    },
    modalTitle() {
      if (this.inventory && this.inventory.id) {
        return this.translations.translate('tplIndependentInventoriesEditTitle', {
          '{a}': (this.inventory.user && this.inventory.user.name) || ''
        })
      }
      return ''
    },
    total() {
      return get(this.inventory, 'stats.stats.dollars', 0)
    },
    visibleItems() {
      return this.items
    },
    gridOptions() {
      return {
        groupDefaultExpanded: -1,
        suppressAggFuncInHeader: true,
        getRowHeight: this.getRowHeightInner,
        autoGroupColumnDef: {
          cellClass: params => (params.node.group && ['wisk-full-width-cell', 'wisk-group-actions-wrapper', 'header']) || [''],
          cellRendererParams: {
            getSubtractFromAllChildrenCount: this.getSubtractFromAllChildrenCount
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'updateBottle']),
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'independent_inventory' } })
    },
    openAddCustomField() {
      this.setGlobalAction({ type: 'customFieldEdit', action: { target: 'independent_inventory' } })
    },
    getSubtractFromAllChildrenCount(params) {
      if (params.wiskGroupActions && !params.node.level) {
        return 1
      }
      return 0
    },
    getRowHeightInner(params) {
      let node = params.node || {},
        children = node.allLeafChildren || [],
        hidden = params?.data?.wiskRowHidden

      if (!hidden && children?.length) {
        hidden = children.every(child => child?.data?.wiskRowHidden)
      }

      return (hidden && 1) || (params.node.group ? 30 : 50)
    },
    emailReports() {
      this.save({ type: 'resend_reports' })
    },
    updateInventoryDuration(value) {
      const operations = []
      if (value[0]) {
        operations[0] = { type: 'start', value: value[0] }
      }
      if (value[1]) {
        operations[1] = { type: 'finish', value: value[1] }
      }
      this.save(operations)
    },
    getInventory() {
      api.independentInventory(this.editAction.id).then(inventoryData => {
        console.log('inventoryData', inventoryData)
        if (inventoryData) {
          this.inventory = inventoryData.current
          this.selectedRange = {
            flatten_by: 'item',
            inventory_range: {
              end_inventory_id: inventoryData.current.id,
              start_inventory_id: inventoryData.current.id
            }
          }
          this.inventoryData = inventoryData
          this.inventoryPair = inventoryData
          this.inventoryDuration = [this.inventory.started_at, this.inventory.finished_at]

          if (this.inventory && this.inventory.locations && this.inventory.locations[0]) {
            this.locationId = this.inventory.locations[0].id

            if (this.inventory.locations[0].measurements) {
              this.entries = this.inventory.locations[0].measurements.map(e => ({ measurement: e, inventory_id: this.inventory.id, id: e.order }))
            }
          }
          // this.key++

          this.getVariance()
        }

        return api.inventoryFlat(this.editAction.id, { flatten_by: 'item', is_independent_inventory: true })
      }).then(inventory => {
        this.setLocalInventoryData(inventory)
      })
    },
    saveItem({ itemId, operation }) {
      this.updateBottle({ id: itemId, operation }).then(() => {
        this.getInventory()
      })
    },
    toggleArchive() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.save({ type: 'archive', value: !this.inventory.archived })
            setTimeout(() => {
              this.setGlobalAction({ type: 'independentInventoryEdit', action: null })
            }, 500)
          },
          message: this.inventory.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.inventory.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    save(operation) {
      if (this.editAction.id) {
        this.loading = true
        api
          .updateIndependentInventory(this.editAction.id, operation)
          .then(() => {
            if (this.editAction.onChange) {
              this.editAction.onChange()
            }
            this.getInventory()
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    setLocalInventoryData(inventory) {
      if (!this.selectedItem) {
        this.items = []
        this.inventoryDuration = [inventory.started_at, inventory.finished_at]
        this.inventory = inventory

        let items = [],
          prepareVariantInfo = variantInfo => {
            let variant = get(this.itemVariantsById, `${variantInfo.item_distributor_id}`)

            if (!variant) {
              return {}
            }
            return {
              ...variantInfo,
              title:
                variant.title + ` - (${variant.measurement.alias ? variant.measurement.alias + ' - ' : ''}${variant.measurement.quantity} ${variant.measurement.unit_of_measurement})`
            }
          }

        const measurements = []
        this.inventoryData.current.locations.forEach(l => {
          measurements.push(...l.measurements)
        })
        for (let i = 0; i < this.inventory.items.value.length; i++) {
          let inventoryItem = this.inventory.items.value[i],
            item = this.bottlesById[inventoryItem.item_id]

          inventoryItem.stats = inventoryItem.stats || { dollars: 0 }
          inventoryItem.stats.locations = inventoryItem.stats.locations || [merge({}, inventoryItem)]

          inventoryItem.stats.item_distributors = get(inventoryItem, 'stats.item_distributors', []).map(prepareVariantInfo)

          inventoryItem.stats.locations.forEach(l => {
            l.stats = l.stats || {}

            l.stats.item_distributors = get(l, 'stats.item_distributors', []).map(prepareVariantInfo)
          })

          if (item) {
            items.push({
              ...item,
              titleSuffix: '',
              inventoryItem,
              inventoryItemMeasurement: measurements.find(m => m.item_id === item.item_id),
              combined_id: inventoryItem.item_id + '_' + ((inventoryItem.location && inventoryItem.location.id) || '') + '_' + inventoryItem.order || '',
              added_at_timestamp: new Date(item.added_at).getTime()
            })
          } else {
            console.warn('missing', inventoryItem.item_id)
          }
        }

        items.sort((a, b) => compareNumbers(get(a, 'inventoryItem.order'), get(b, 'inventoryItem.order')))
        this.items = items
        this.loading = false
      }
    },
    getDefaultVariantFromItemId(itemId) {
      let item = this.bottlesById[itemId] || { item_distributor_ids: [] }
      return item.item_distributor_ids.map(id => this.itemVariantsById[id]).find(v => v && v.is_default)
    },
    getGridColumns() {
      this.columns = getItemsGridColumns({
        translations: this.translations,
        getDefaultVariantFromItemId: this.getDefaultVariantFromItemId,
        viewMode: this.viewMode,
        itemVariantsById: this.itemVariantsById
      })

    },
    varianceGroupTitlesByItemId() {
      if (this.variance && this.variance.variance_groups && this.variance.variance_groups.length) {
        let result = {}
        this.variance.variance_groups.forEach(varianceGroup => {
          if (varianceGroup && varianceGroup.item_ids && varianceGroup.item_ids.length) {
            varianceGroup.item_ids.forEach(itemId => {
              result[itemId] = varianceGroup
            })
          }
        })
        return result
      }
      return {}
    },
    mapItems() {
      this.varianceItems = []

      if (!this.varianceLoadingOverlay && this.variance && this.variance.bottles && this.variance.bottles.length) {
        let varianceGroupTitlesByItemId = this.varianceGroupTitlesByItemId()
        this.variance.bottles.forEach(item => {
          let bottle = this.bottlesById[get(item, 'bottle.item_id', 0)]
          if (!bottle) {
            console.warn('Bottle not found while mapping Variance items', item)
            bottle = {}
          }
          bottle.varianceGroup = varianceGroupTitlesByItemId[bottle.item_id]
          this.varianceItems.push({ ...bottle, ...item })
        })
      }
    },
    getVariance() {
      if (this.inventory?.id) {
        this.varianceLoadingOverlay = true
        api
          .variance({ end_inventory_id: this.inventory?.id, is_independent_inventory: true })
          .then(variance => {
            this.variance = variance
            this.varianceLoadingOverlay = false
            if (!variance.result?.no_previous_inventory) {
              this.mapItems()
            }
          })
          .catch(() => {
            this.varianceLoadingOverlay = false
          })
      }
    },
  },
  mounted() {
    if (this.$route.query.action_tab) {
      this.viewMode = this.$route.query.action_tab
    }
  },
  watch: {
    editAction: {
      immediate: true,
      handler() {
        if (this.editAction?.id) {
          if (this.editAction?.tab) {
            this.viewMode = this.editAction.action
          }

          this.getInventory()
          this.getGridColumns()
        }
      }
    },
    viewMode() {
      if (this.viewMode === 'variance' && this.variance?.result?.no_previous_inventory) {
        this.viewMode = 'edit'
      }
    }
  }
}
</script>


<style lang="scss"></style>
