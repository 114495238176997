<template>
  <div class="role-operation-error-handler">
    <h5 class="mb-4 text-danger">
      {{ translations.txtRoleArchiveError }}
    </h5>
    <venuesUsers :usersVenuesData="usersVenuesData" @refresh="getUsersVenuesData" parentGridName="RoleArchiveErrorHandler" gridAutoHeight
      :hideColumns="['more', 'dropdownMenu', 'checkboxSelection', 'venueTitle', 'venue', 'userNotificationTypes']" hideHeader />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '@/api'
import venuesUsers from '@/components/users/VenuesUsers'

export default {
  name: 'RoleArchiveErrorHandler',
  components: { venuesUsers },
  props: { userIds: Array },
  data() {
    return {
      usersVenuesData: []
    }
  },
  computed: {
    ...mapState(['translations', 'user', 'venue', 'rolesByVenueId']),
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    getUsersVenuesData() {
      if (this.user) {
        api.usersVenues({ venueId: this.venue.id }).then(data => {
          let usersVenuesData = []

          data.forEach(item => {
            item = { ...item, venue_title: item.venue.title, user_full_name: item.user.name, user_email: item.user.email }
            item.venue.roles = this.rolesByVenueId[item.venue.id]

            if (this.userIds.includes(item.user.id) && (!item.user.is_wisk_user || this.user.is_wisk_user || this.user.god_mode)) {
              usersVenuesData.push(item)
            }
          })

          this.usersVenuesData = usersVenuesData
        })
      }
    }
  },
  created() {
    this.getUsersVenuesData()
  },
  watch: {}
}
</script>

<style lang="scss"></style>
