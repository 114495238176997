<template>
  <div class="w-100">
    <wiskGrid v-bind="$attrs" :columnDefs="columnDefs" :rowData="localItemsComputed" @requestFilter="$emit('requestFilter', $event)"
      :gridOptions="gridOptions" :loadingOverlay="loading" @selectedRowsChanged="$emit('selectedRowsChanged', $event)" parentGridName="SubRecipesGrid"
      @visibleRows="$emit('visibleRows', $event)" @rowNodes="rowNodes = $event" @gridApi="gridReady" :header="{}" :customFilter="customFilter" :defaultFilter="defaultFilter">

      <template v-slot:additional-header-controls>
        <slot></slot>
      </template>
    </wiskGrid>
    <confirm ref="confirmDialog" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { prepareSubrecipe } from '@/modules/utils'
import wiskGrid from '@/components/grids/WiskGrid'
import { getGridColumns } from './columnDefs'

export default {
  name: 'SubrecipesGrid',
  emits: ['gridApi', 'requestFilter', 'selectedRowsChanged', 'visibleRows', 'openEditSubrecipe'],
  components: {
    wiskGrid
  },
  props: {
    openSubrecipeEdit: { type: Boolean, default: true },
    items: Array,
    loading: Boolean,
    defaultFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function',
      default: () => ({ predicate: item => !item.archived, name: 'active', label: 'Active', hidesArchivedItems: true })
    },
    customFilter: {
      type: Object,
      validator: value => value && typeof value.predicate === 'function'
    }
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnDefs: [],
      rowNodes: []
    }
  },
  computed: {
    ...mapGetters(['venue', 'configDataLoaded']),
    ...mapState(['translations', 'subrecipes', 'bottlesById', 'allergens', 'firestoreInitialLoadComplete', 'subrecipesById', 'subrecipeCostById', 'currentPermissionsByType', 'customFieldsByTarget']),
    localItemsComputed() {
      return this.items.map(subrecipe => prepareSubrecipe(subrecipe, this.$store.state))
    }
  },
  methods: {
    ...mapActions(['setSubrecipe', 'setGlobalAction']),
    gridReady(api) {
      this.gridApi = api
      this.$emit('gridApi', api)
    },
    deselectAll() {
      this.gridApi.deselectAll()
    },
    openEditSubrecipe(id) {
      this.$emit('openEditSubrecipe', id)

      if (this.openSubrecipeEdit) {
        this.setGlobalAction({ type: 'subrecipeEdit', action: { id } })
      }
    },
    save({ value, id, type, previousValue }) {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.setSubrecipe({
              id,
              operation: { type, value, from: previousValue }
            })
          },
          message: value ? this.translations.confirmArchiveText : this.translations.confirmRestoreText,
          title: value ? this.translations.confirmArchiveTitle : this.translations.confirmRestoreTitle,
          autoConfirm: type !== 'archive'
        })
      }
    },
    createColumns() {
      if (this.configDataLoaded) {
        this.columnDefs = getGridColumns({
          translations: this.translations,
          save: this.save,
          setGlobalAction: this.setGlobalAction,
          openEditSubrecipe: this.openEditSubrecipe,
          currentPermissionsByType: this.currentPermissionsByType,
          allergens: this.venue.venue_type === 'food' ? this.allergens : null,
          customFields: (this.customFieldsByTarget && this.customFieldsByTarget.subrecipe) || []
        })
      }
    },
    //called from outside
    getNextRowNode(nodeId) {
      let node = this.gridApi.getRowNode(nodeId)

      if (node && node.rowIndex < this.rowNodes.length - 1) {
        return this.rowNodes[node.rowIndex + 1]
      }
      return null
    },
    //called from outside
    getPreviousRowNode(nodeId) {
      let node = this.gridApi.getRowNode(nodeId)

      if (node && node.rowIndex > 0) {
        return this.rowNodes[node.rowIndex - 1]
      }
      return null
    }
  },
  created() {
    this.gridOptions = {
      headerHeight: 30,
      getRowHeight: params => (params?.data?.ingredients?.length || 1) * 35,
      rowClassRules: {
        'warning-row': 'data && (data.unmapped || !data.ingredients.length)',
        'danger-row': 'data?.archived'
      }
    }
  },
  mounted() {
    this.createColumns()
  },
  beforeUnmount() {
    this.gridApi = null
  },
  watch: {
    localItemsComputed() {
      this.gridApi && this.gridApi.resetRowHeights()
    },
    customFilter: 'deselectAll',
    configDataLoaded: 'createColumns',
    'customFieldsByTarget.subrecipe': 'createColumns'
  }
}
</script>

<style>
.percentage-warning {
  background-color: var(--bs-warning) !important;
}
</style>
