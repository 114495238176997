<template>
  <div :class="[`size-${size}`, { 'plain-text': showPlainText, 'additional-button-present': operationTriggerButtonComputed, 'hidden': hidden, 'highlighted': highlighted, 'fit-children-tight': fitChildrenTight }]"
    v-if="mounted" :title="tooltip" class="form-group wisk-input">
    <b-row :no-gutters="noGutters">
      <b-col :cols="fitChildren ? 12 - fitChildrenCols : 12" v-if="!dummy" :class="{ 'pe-0': fitChildrenTight }">
        <component :autofocus="autofocus" :id="domId" :is="componentType" v-bind="$attrs" @autocompleteSelected="autocompleteSelected" :urlInput="urlInput"
          :className="`${!(['checkbox', 'range']).includes(inputType) && 'form-control'} ${computedClass} ${inputClass} ${validationClass}`" ref="inputComponent" @autocompleteInput="autocompleteInput"
          @blur="onBlur" :icon="icon" :disabled="disabledLocal" :decimals="decimals" :showPlainText="showPlainText" :label="label + validationStar" :required="required"
          :thousandsSeparator="thousandsSeparator" @focus="onFocus" :prefix="prefix" :suffix="suffix" @input="onInput" @enter="$emit('enter', $event)" :autocompleteWidth="autocompleteWidth"
          :customUnFormatter="customUnFormatter" :customFormatter="customFormatter" :input-class="`form-control ${inputClassComputed} ${inputClass} ${validationClass}`" :value="localValue"
          :type="inputTypeComputed" :autocompleteFirstItemSlotUsed="autocompleteFirstItemSlotUsed" :autocomplete="autocomplete" :min="min" :max="max">

          <template v-slot:autocompleteFirstItemInner>
            <slot name="autocompleteFirstItem">
            </slot>
          </template>
          <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope || {}" />
          </template>
        </component>
        <b-button variant="link" @click="clearAutocomplete" v-if="!preventClearAutocomplete" v-show="autocompleteItemSelected" :disabled="disabled" style="top: 1px; position: absolute;" :style="{ right: noGutters ? '0' : '12px' }" :title="translations.txtGenericClear">
          <icon class="text-primary" :scale="0.7" name="wisk-exit"></icon>
        </b-button>

        <div v-show="!isValid && shouldValidate" class="text-small-info px-2 text-danger text-bold input-helper-text text-start">
          {{ validationMessage }}
        </div>
        <div v-show="(isValid || !shouldValidate) && helperText" class="text-small-info px-2 input-helper-text text-start">
          {{ helperText }}
        </div>
      </b-col>
      <b-col class="wisk-input-child" :class="dummy ? '' : 'ps-0'" v-if="fitChildren && fitChildrenCols" :cols="dummy ? 12 : fitChildrenCols">
        <slot></slot>
      </b-col>
    </b-row>
    <b-row v-if="!fitChildren">
      <b-col>
        <slot></slot>
      </b-col>
    </b-row>
    <b-button variant="link" class="" style="position: absolute; right: 5px; top: 5px;" size="sm" @click="triggerOperation" :title="translations.txtGenericUse" v-if="operationTriggerButtonComputed">
      <icon class="" name="wisk-clone"></icon>
    </b-button>

    <slot name="append"></slot>
    <confirm v-if="operationConfirm?.message" ref="confirmDialog" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import equal from 'fast-deep-equal/es6'
import cloneDeep from 'lodash.clonedeep'
import { guid, formatDate } from '@/modules/utils'
import numberInput from '@/components/common/MaskInput'
import textInput from '@/components/common/TextInput'
import rangeInput from '@/components/common/RangeInput'
import numberRangeInput from '@/components/common/NumberRangeSelector'
import checkboxInput from '@/components/common/CheckboxInput'
import attachmentsInput from '@/components/common/AttachmentsInput'
import datepickerInput from '@/components/common/DatePickerInput'
import gridMultiSelectInput from '@/components/common/GridMultiSelectInput'
import timePickerInput from '@/components/common/TimePicker'

//Known issue: operation event is not working together with v-model

export default {
  name: 'WiskInput',
  emits: ['update:modelValue', 'mounted', 'operation', 'clear', 'focus', 'blur', 'change', 'enter', 'autocompleteInput', 'autocompleteSelected', 'valid'],
  components: { numberInput, timePickerInput, textInput, checkboxInput, datepickerInput, rangeInput, attachmentsInput, gridMultiSelectInput, numberRangeInput },
  props: {
    modelValue: null,
    autocompleteItemExists: Boolean,
    preventClearAutocomplete: Boolean,
    operationTriggerButton: Boolean,
    preventOperationTriggerButton: Boolean,
    preventDisabledFromParent: Boolean,
    highlightOnValueChange: Boolean,
    debounceSetOperationTimeout: { type: Number, default: 0 },
    valueName: String,
    fitChildren: Boolean,
    fitChildrenTight: Boolean,
    fitChildrenCols: { type: Number, default: 6 },
    autofocus: Boolean,
    autocomplete: Boolean,
    urlInput: Boolean,
    dummy: Boolean,
    passwordField: Boolean,
    customFieldValueWrapper: Object,
    inputType: {
      type: String,
      default: 'text',
      validator: value => ['text', 'number', 'checkbox', 'datepicker', 'range', 'numberRange', 'attachments', 'gridMultiSelect', 'timePicker'].includes(value)
    },
    customFormatter: Function,
    customUnFormatter: Function,
    hidden: Boolean,
    triggerInputOnLoad: Boolean,
    triggerInputOnSet: Boolean,
    label: { type: String, default: '' },
    helperText: { type: String, default: '' },
    icon: { type: String, default: null },
    noGutters: { type: Boolean, default: false },
    showPlainText: Boolean,
    inputClass: String,
    placeholder: String,
    operation: String,
    operationEmpty: String,
    clearAfterEmitOperation: Boolean,
    decimals: Number,
    min: [Date, Number],
    max: [Date, Number],
    thousandsSeparator: String,
    autocompleteFirstItemSlotUsed: Boolean,
    autocompleteWidth: Number,
    operationConfirm: Object,
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    disabled: Boolean,
    required: Boolean,
    requiredAutocompleteItem: Boolean,
    validations: {
      type: Array,
      default: () => []
    },
    size: { type: String, default: 'md' },
    tooltipOverride: String,
  },
  data() {
    return {
      isValid: true,
      oldValue: null,
      localValue: null,
      shouldValidate: false,
      inputGroupParent: null,
      disabledLocal: false,
      autocompleteItemSelected: false,
      operationTriggerButtonLocal: false,
      highlightOnValueChangeLocal: false,
      validationMessage: '',
      mounted: false,
      emitChangeTimeoutId: null,
      highlighted: false
    }
  },
  computed: {
    ...mapState(['translations']),
    validationClass() {
      if (this.shouldValidate) {
        return this.isValid ? 'is-valid' : 'is-invalid'
      }
      return ''
    },
    validationStar() {
      if (this.required) {
        return '*'
      }
      return ''
    },
    inputClassComputed() {
      if (this.inputType === 'timePicker') {
        return 'has-value'
      }
      return ''
    },
    computedValue() {
      if (this.inputType === 'number') {
        return this.modelValue || 0
      }
      if (this.inputType === 'range') {
        return this.modelValue || 0
      }
      if (this.inputType === 'numberRange') {
        return Array.isArray(this.modelValue) ? [...this.modelValue] : [0, 0]
      }
      if (this.inputType === 'string') {
        return this.modelValue || ''
      }

      if (this.inputType === 'attachments') {
        return Array.isArray(this.modelValue) ? this.modelValue : [this.modelValue]
      }
      if (this.inputType === 'datepicker') {
        if (this.modelValue && this.modelValue.length && this.modelValue[this.modelValue.length - 1] === 'Z') {
          return new Date(this.modelValue)
        }
        if (this.modelValue && typeof this.modelValue.toDate === 'function') {
          return this.modelValue.toDate()
        }
      }
      return this.modelValue
    },
    hasChange() {
      return !equal(this.localValue, this.oldValue)
    },
    canSetOperation() {
      return this.hasChange && this.isValid
    },
    tooltip() {
      if (this.tooltipOverride) {
        return this.tooltipOverride
      }
      if (this.inputType === 'datepicker') {
        return formatDate(this.modelValue)
      }
      if (this.inputType === 'attachments') {
        return ''
      }
      if (this.modelValue === -1111111) {
        return ''
      }
      return this.passwordField ? '' : this.modelValue
    },
    componentType() {
      return `${this.inputType}Input`
    },
    computedClass() {
      let label = (this.label && this.label.replace(/ /gi, '_')) || '',
        base = `wisk-input-label-${label}`

      if (this.inputType === 'number') {
        return base + ' text-end pe-4'
      }

      if (this.disabledLocal) {
        return base + ' readonly'
      }

      return base
    },
    domId() {
      return 'z-' + (this.label || '').toLowerCase().replace(/[\W_]+/g, '-') + '-' + guid()
    },
    operationTriggerButtonComputed() {
      if (this.preventOperationTriggerButton) {
        return false
      }
      return this.operationTriggerButton || this.operationTriggerButtonLocal
    },
    highlightOnValueChangeComputed() {
      return this.highlightOnValueChange || this.highlightOnValueChangeLocal
    },
    inputTypeComputed() {
      if (this.passwordField) {
        return 'password'
      }
      if (this.urlInput) {
        return 'url'
      }

      return ''
    }
  },
  methods: {
    autocompleteInput(value) {
      this.$emit('autocompleteInput', value)
    },
    autocompleteSelected(event) {
      this.$emit('autocompleteSelected', event)
      this.autocompleteItemSelected = true
    },
    focus() {
      this.$refs.inputComponent && this.$refs.inputComponent.focus()
    },
    clearAutocomplete() {
      if (this.$refs.inputComponent && this.$refs.inputComponent.setValue) {
        this.$refs.inputComponent.setValue('')
        this.localValue = ''
        this.autocompleteItemSelected = false
        this.isValid = this.validate(this.localValue)
        this.shouldValidate = false
        this.$refs.inputComponent.focus()
        this.$emit('update:modelValue', '')
        this.$emit('clear')
      }
    },
    //called from outside
    getInputComponentRef() {
      return this.$refs.inputComponent
    },
    initValue() {
      if (this.$refs.inputComponent?.setValue && ((this.inputType === 'numberRange' && !equal(this.computedValue, this.localValue)) || this.inputType !== 'numberRange')) {
        this.$refs.inputComponent.setValue(this.computedValue)
      }

      this.localValue = cloneDeep(this.computedValue)
      this.oldValue = cloneDeep(this.computedValue)
      this.isValid = this.validate(this.localValue)
    },
    resetValue() {
      if (this.$refs.inputComponent && this.$refs.inputComponent.setValue) {
        this.$refs.inputComponent.setValue(this.oldValue)
      }
      console.log('this.oldValue', this.oldValue)
      this.localValue = cloneDeep(this.oldValue)
      this.isValid = this.validate(this.localValue)
      this.autocompleteItemSelected = false
    },
    validate(value) {
      let ok = true

      if (this.required && ((['gridMultiSelect'].includes(this.inputType) && !value.length) || !value)) {
        ok = false
        this.validationMessage = this.translations.txtValidationRequired
      }

      if (this.requiredAutocompleteItem && this.autocomplete && !this.autocompleteItemSelected) {
        ok = false
        this.validationMessage = this.translations.txtValidationRequiredItemSelected
      }

      if (Array.isArray(this.validations)) {
        for (let i = 0; i < this.validations.length; i++) {
          const validation = this.validations[i]
          ok = validation.validator(value, this.localValue)
          if (!ok) {
            i = this.validations.length
            this.validationMessage = validation.message
          }
        }
      }
      return ok
    },
    onFocus() {
      this.shouldValidate = false
      this.$emit('focus')
    },
    emitOperation() {
      if (this.operationConfirm?.message && this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.$emit('operation', this.getOperation())

            if (this.operationConfirm?.callback) {
              this.operationConfirm.callback()
            }
          },
          cancelCallback: () => {
            let copy = this.localValue
            this.resetValue()

            if (this.operationConfirm?.cancelCallback) {
              this.operationConfirm.cancelCallback(copy)
            }
          },
          okText: this.operationConfirm.okText,
          cancelText: this.operationConfirm.cancelText,
          message: this.operationConfirm.message,
          title: this.operationConfirm.title || this.translations.confirmItemsGridFloatingActionTitle
        })
      } else {
        this.$emit('operation', this.getOperation())
      }
    },
    onInput(value) {
      if (!this.disabledLocal) {
        this.$emit('update:modelValue', value)

        this.localValue = value

        this.isValid = this.validate(value)
        this.setValidState()

        if (this.operation) {
          this.canSetOperation ? this.setOperation() : this.removeOperation()
        }

        if (this.hasChange) {
          this.emitChange()
        }

        if (['datepicker', 'timePicker', 'range', 'attachments', 'gridMultiSelect', 'numberRange'].includes(this.inputType)) {
          setTimeout(() => {
            if (this.hasChange) {
              this.emitOperation()

              if (this.clearAfterEmitOperation) {
                setTimeout(() => {
                  this.resetValue()
                }, 1000)
              }
            }
          }, 0)
        }
      }
    },
    triggerValidationCheck() {
      this.shouldValidate = true
      this.isValid = this.validate(this.localValue)
      this.setValidState()
    },
    setValidState() {
      if (this.inputGroupParent?.setValidState) {
        this.inputGroupParent.setValidState({ name: this.domId, hasError: !this.isValid })
      }

      this.$emit('valid', this.isValid)
    },
    getOperation() {
      let operation = {},
        value = this.localValue

      if (typeof this.localValue === 'string') {
        value = value.trim()
      }
      if (this.localValue && this.localValue.getTime && this.inputType === 'datepicker') {
        value = this.localValue.toISOString()
      }

      operation.value = value
      operation.type = this.operation
      operation.from = this.oldValue

      if (this.customFieldValueWrapper) {
        let copy = { ...this.customFieldValueWrapper }
        copy.value.value = value //so many value

        operation.value = copy
      }

      if (!value && this.operationEmpty) {
        operation.type = this.operationEmpty
      }
      return operation
    },
    setOperation() {
      if (this.inputGroupParent && this.inputGroupParent.setOperation) {
        let operation = this.getOperation()

        this.inputGroupParent.setOperation({
          operation,
          allowDuplicateOperationType: !!this.customFieldValueWrapper,
          operationTypes: { set: this.operation, clear: this.operationEmpty }
        })
      }
    },
    removeOperation() {
      if (this.inputGroupParent && this.inputGroupParent.removeOperation) {
        this.inputGroupParent.removeOperation({ type: this.operation, operationTypes: { set: this.operation, clear: this.operationEmpty } })
      }
    },
    emitChange() {
      clearTimeout(this.emitChangeTimeoutId)
      this.emitChangeTimeoutId = setTimeout(() => {
        this.$emit('change', this.localValue)
      }, 1000)
    },
    onBlur(event) {
      this.shouldValidate = true

      if (event && event.stop) {
        this.resetValue()
      }

      if (this.hasChange || (this.urlInput && this.isValid)) {
        this.emitOperation()

        if (this.clearAfterEmitOperation) {
          setTimeout(() => {
            this.resetValue()
          }, 1000)
        }
      }
      this.$emit('blur', event)
    },
    triggerOperation() {
      this.setOperation()
      let value = this.localValue

      if (this.customFieldValueWrapper) {
        value = { ...this.customFieldValueWrapper }
        value.value.value = this.localValue //value.value.value.value.value.....
      }

      this.inputGroupParent && this.inputGroupParent.$emit('value', { [this.valueName || this.operation]: value })
    }
  },
  mounted() {
    if (this.operationEmpty && this.required) {
      console.error(this.label, ` - Can't have a clear operation at the same time with a required flag: operationEmpty: ${this.operationEmpty}`)
    }

    this.autocompleteItemSelected = !!this.autocompleteItemExists

    this.mounted = true
    let parent = this.$parent
    while (parent && !this.inputGroupParent && parent !== this.$root) {
      if (parent.inputGroup) {
        this.inputGroupParent = parent
      }
      parent = parent.$parent
    }
    if (this.inputGroupParent) {
      this.disabledLocal = !!this.inputGroupParent.disabled
      this.operationTriggerButtonLocal = !!this.inputGroupParent.operationTriggerButton
      this.highlightOnValueChangeLocal = !!this.inputGroupParent.highlightOnValueChange

      this.setValidState()
    }
    this.disabledLocal = this.disabledLocal || this.disabled

    if (this.preventDisabledFromParent) {
      this.disabledLocal = false
    }
    setTimeout(() => {
      this.initValue()

      if (this.triggerInputOnLoad) {
        this.triggerOperation()
      }

      this.$emit('mounted')
    }, 10)
  },
  beforeUnmount() {
    clearTimeout(this.emitChangeTimeoutId)
  },
  watch: {
    autocompleteItemExists(autocompleteItemExists) {
      this.autocompleteItemSelected = !!autocompleteItemExists
    },
    computedValue() {
      if (this.highlightOnValueChangeComputed) {
        this.highlighted = true

        setTimeout(() => {
          this.highlighted = false
        }, 1000)
      }
    },
    isValid: { handler: 'setValidState' },
    modelValue() {
      this.initValue()

      if (this.triggerInputOnSet) {
        this.triggerOperation()
      }
    },
    disabled() {
      this.disabledLocal = this.disabled
    },
    'inputGroupParent.disabled': {
      handler() {
        this.disabledLocal = this.inputGroupParent.disabled || this.disabled
      }
    },
    autocompleteItemSelected(autocompleteItemSelected) {
      this.disabledLocal = autocompleteItemSelected || this.disabled
    }
  }
}
</script>

<style lang="scss">
/* webkit solution */
::-webkit-input-placeholder {
  text-align: left;
}

/* mozilla solution */
input:-moz-placeholder {
  text-align: left;
}

.text-bold {
  font-weight: 700;
}

.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
  border-color: var(--bs-border-color);
}

.wisk-input.form-group {
  position: relative;

  &.plain-text {
    border: none;
    outline: none;
    background-color: transparent;
    color: inherit;
    box-shadow: none;
    padding: 0;
    text-align: center;
  }

  &.fit-children-tight {
    .form-control {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right-width: 0px;
    }

    .wisk-input-child {
      .multiselect .multiselect__tags {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right-width: 1px;
      }

      .wisk-input-append-plain-component {
        border: 1px solid var(--bs-border-color);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-left: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }
  }

  &.border-radius-right-none {
    .form-control {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.border-radius-left-none {
    .form-control {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.floating-shadow {
    width: 100%;
    max-width: 450px;
    border: none;
    margin-bottom: 25px;

    input {
      font-size: 16px;
    }

    .form-control {
      box-shadow: 0px 15px 30px rgba(196, 208, 220, 0.8);
      max-height: 45px;
      height: 45px;
      background-color: white;
      border: none;
      border-radius: 11px;
    }

    label {
      font-size: 18px;
      margin: 2px 10px;
    }

    .input-helper-text {
      text-align: right !important;
      font-size: 16px;
      font-weight: 500;
      margin-top: 20px;
    }
  }

  &.hidden {
    visibility: hidden !important;
    height: 0 !important;
    width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute;
    top: -1000px;
  }

  .input-helper-text {
    padding-bottom: 5px;

    &:not(.text-danger) {
      opacity: 0.6;
    }

    //outline: 1px solid red;
  }

  ::placeholder:not(.no-placeholder-uppercase) {
    text-transform: capitalize;
  }

  label {
    &:first-letter {
      text-transform: uppercase;
    }
  }

  &.additional-button-present {
    padding-right: 60px;
  }

  .wisk-input.form-group {
    margin-bottom: 0;
  }

  .validation {
    position: absolute;
    top: 14px;
    right: 25px;
  }
}

@media (max-width: 1019px) {
  .wisk-input.form-group {
    &.floating-shadow {
      .form-control {
        box-shadow: 0px 15px 30px rgba(196, 208, 220, 0.8);
        max-height: 34px;
        height: 34px;
        background-color: white;
        border: none;
        border-radius: 8px;
      }

      label {
        font-size: 14px;
        margin: 1px 9px;
        color: #828282;
      }

      .input-helper-text {
        text-align: right !important;
        font-size: 12px;
        font-weight: 400;
        margin-top: 18px;
      }
    }
  }
}
</style>
