<template>
  <wiskModal :visible="!!editAction" size="lg" @ok="save" :okText="translations.txtGenericSave" v-if="editAction" :showNavigationRight="!!editAction?.next" @goRight="editAction.next"
    @hide="setGlobalAction({ type: 'subrecipeEdit', action: null })" :okDisabled="!valid" :extraLarge="!!(subrecipe && subrecipe.id)"
    :hideFooter="!!(subrecipe && subrecipe.id)" @goLeft="editAction.previous" :showNavigationLeft="!!editAction?.previous">
    <template v-slot:modal-title>
      <b-row>
        <b-col cols="2" class="px-0" v-if="subrecipe">
          <div class="position-relative images-controls-wrapper">
            <imageView :src="subrecipe.image || '3d5df52e-18ea-4d36-9d26-7b3059f73a5f.png'" @click="editSubrecipeImage" filterDisplay="tinyThumb" />
            <div class="position-absolute" v-if="subrecipe.image" style="bottom: 0; right: 25px;">
              <b-button variant="link" @click="removeSubrecipeImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericRemove">
                <icon name="wisk-trash" class="image-view-overlay-icon text-danger" :scale="1" />
              </b-button>
            </div>
            <div class="position-absolute" style="bottom: 0; right: 0;">
              <b-button variant="link" @click="editSubrecipeImage" class="bt-delete-image p-1" v-tooltip="translations.txtGenericEdit">
                <icon name="wisk-edit" class="image-view-overlay-icon text-primary" :scale="1" />
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col cols="10" :class="{ warning: !!(subrecipe && subrecipe.archived) }">
          <h5 class="modal-title mb-2"> {{ modalTitle }} </h5>
        </b-col>
      </b-row>
    </template>

    <template v-slot:modal-header-extra v-if="subrecipe && subrecipe.id">
      <b-row>
        <b-col>
          <b-form-radio-group style="margin-left: 5px;" size="sm" buttons v-model="selectedEditorTab" name="titleSearchLocal" button-variant="outline-primary">
            <b-form-radio :value="0"> {{ translations.txtGenericEdit }} </b-form-radio>
            <b-form-radio :value="1"> {{ translations.txtGenericUsedIn }} </b-form-radio>
            <b-form-radio :value="2"> {{ translations.txtGenericTimeline }} </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col>
          <wiskActions :title="translations.txtGenericActions" :actions="pageActions" />
        </b-col>
      </b-row>
    </template>
    <div class="min-h-440">
      <div v-if="selectedEditorTab === 0">
        <wiskInputGroup :disabled="!currentPermissionsByType.batch_manage" class="px-0 pinput" style="" v-if="subrecipe && subrecipe.yields" @errorCountChanged="setValidState" @operationsChange="operations = $event">
          <b-row>
            <b-col :md="subrecipe && subrecipe.id ? 4 : 6" cols="12" class=" mb-2-m">
              <wiskInput infoTooltipKey="93dc47ea-6bda-4c8e-b88b-80de5806a203" :label="translations.txtGenericTitle" :modelValue="subrecipe.title" required operation="title" autofocus
                :triggerInputOnLoad="!editAction.id" @operation="singleFieldSave" />
            </b-col>
            <b-col md="4" cols="12" class=" mb-2-m" v-if="subrecipe && subrecipe.id">
              <wiskInput infoTooltipKey="12c42b8c-f5a2-4224-9847-d9f90f2b910e" :label="translations.txtGenericCost" :modelValue="subrecipe.dollars" disabled inputType="number" :decimals="2" :prefix="currency" />
            </b-col>
            <b-col md="4" cols="12" class=" mb-2-m" v-if="subrecipe && subrecipe.id">
              <wiskInput infoTooltipKey="93fe44a4-5e47-4977-86c9-dceb0f32e470" :label="translations.txtSubrecipesAsItem" :modelValue="subrecipe.used_as_inventory_item" operation="used_as_inventory_item" inputType="checkbox"
                :triggerInputOnLoad="!editAction.id" @operation="singleFieldSave" class="mb-0" />

              <b-button class="pt-0" style="float: left;" v-if="subrecipe && subrecipe.inventory_item_id" @click="setGlobalAction({ type: 'itemEdit', action: { item_id: subrecipe.inventory_item_id } })" variant="link">
                {{ translations.txtSubrecipesLinkedItem }}
              </b-button>
            </b-col>
            <b-col md="6" cols="12" class="mb-2-m" v-if="subrecipe && !subrecipe.id">
              <measurement v-model="subrecipe.yields.measurement" @operation="singleFieldSave" :triggerInputOnLoad="!editAction.id" infoTooltipKey="subrecipeYields"
                :extraUnitsOfMeasurement="[{ id: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]"
                operation="yields_measurement" :label="translations.txtGenericYields" :inline="!subrecipe.id" horizontal />
            </b-col>
          </b-row>
        </wiskInputGroup>
        <b-row v-if="subrecipe && subrecipe.id">
          <b-col>
            <!-- :gridStyle="{ height: 'calc(100vh - 460px)' }" -->
            <wiskItemsGrid class="p-0 mb-4" gridAutoHeight :header="{}" :columns="itemsGridColumns" :items="ingredients" trackBy="gridId"
              :defaultFilter="{ predicate: () => 1 }" :key="gridKey" parentGridName="SubRecipeIngredients" :selectedRowsActionsOverride="selectedRowsActions" />
          </b-col>
        </b-row>
        <b-row v-if="subrecipe && subrecipe.id">
          <b-col class="text-center" cols="12">
            <measurement class="mx-auto" style="max-width: 250px;" v-model="subrecipe.yields.measurement" @operation="singleFieldSave" :triggerInputOnLoad="!editAction.id"
              :extraUnitsOfMeasurement="[{ id: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]"
              operation="yields_measurement" :label="translations.txtGenericYields" :inline="!subrecipe.id" horizontal :disabled="!currentPermissionsByType.batch_manage" />
          </b-col>
        </b-row>
        <b-row v-if="subrecipe && subrecipe.id">
          <b-col>
            <addIngredient :existingIngredientsIds="existingIngredientsIds" @add="addIngredient" :disabled="!currentPermissionsByType.batch_manage || subrecipe.archived" />

            <customFields target="subrecipe" :item="subrecipe" @operation="singleFieldSave" />

            <wiskInput infoTooltipKey="018d9ffd-9ce6-4003-a50c-a83916e021f0" :valueCol="10" :labelCol="2" :label="translations.txtGenericNotes"
              multiline autoHeightTextArea :modelValue="subrecipe.notes" inputType="text" operation="notes" @operation="singleFieldSave" />
          </b-col>
        </b-row>
      </div>
      <div v-if="selectedEditorTab === 1">
        <itemUsageGrid :item="subrecipe" />
      </div>
      <div v-if="selectedEditorTab === 2">
        <timeline :filters="[{ type: 'subrecipe', id: subrecipe.id }]" parentGridName="SubrecipeEditTimeline" :gridStyle="{ height: 'calc(100vh - 302px)' }" />
      </div>
    </div>

    <measurement v-if="selectedCustomServingSizeIngredient && selectedCustomServingSizeIngredient.measurement" :modelValue="selectedCustomServingSizeIngredient.serving_size.measurement"
      disableTypeChange @update:modelValue="saveCustomServingSize" startOpened @hide="selectedCustomServingSizeIngredient = null" :preferredType="selectedCustomServingSizeIngredient.measurement.type"
      :extraUnitsOfMeasurement="[{ id: 'unit', title: 'Unit', units_of_measurements: [{ id: 'unit', short: 'unit', title: 'Unit', type: 'unit' }] }]" />

    <confirm ref="confirmDialog" />
    <wiskLoading :loading="loading" />

    <wiskModal :title="translations.txtSubrecipesCopyToVenues" v-model="copyToVenuesDialogVisible" hideHeader hideOK size="sm">
      <div class="px-4">
        <subrecipesCopyToVenues v-if="copyToVenuesDialogVisible" :rows="[subrecipe]" clearAfterEmitOperation />
      </div>
    </wiskModal>
  </wiskModal>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import merge from 'lodash.merge'
import get from 'lodash.get'
import api from '@/api'
import { prepareSubrecipe } from '@/modules/utils'
import measurement from '@/components/bottles/Measurement'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import subrecipesCopyToVenues from '@/components/subrecipes/SubrecipesCopyToVenues'
import addIngredient from '@/components/common/IngredientAdd'
import timeline from '@/components/timeline/Timeline'
import customFields from '@/components/customFields/CustomFieldsRender'
import imageView from '@/components/common/ImageView'
import itemUsageGrid from '@/components/bottles/ItemUsageGrid'
import { getItemsGridColumns } from './columnDefs'

const newSubrecipe = {
  id: 0,
  title: '',
  yields: {
    measurement: {
      type: 'volume',
      quantity: 1,
      unit_of_measurement: 'oz'
    }
  }
}
export default {
  name: 'SubrecipeEdit',
  components: { measurement, wiskItemsGrid, addIngredient, imageView, itemUsageGrid, timeline, customFields, subrecipesCopyToVenues },
  props: {
    editAction: Object
  },
  data() {
    return {
      valid: true,
      selectedCustomServingSizeIngredient: null,
      selectedEditorTab: 0,
      gridKey: 1,
      loading: false,
      subrecipe: null,
      operations: [],
      itemsGridColumns: {},
      availableVenuesForCopy: [],
      copyToVenuesDialogVisible: false,
    }
  },
  computed: {
    ...mapState(['firestoreInitialLoadComplete', 'user', 'subrecipeCostById', 'translations', 'subrecipesById', 'bottlesById', 'servingSizes', 'allergens', 'currentPermissionsByType', 'customFieldsByTarget']),
    ...mapGetters(['configDataLoaded', 'currency', 'venue', 'permissionsByVenueIdByType']),
    modalTitle() {
      if (this.subrecipe) {
        let archived = this.subrecipe.archived ? ` - (${this.translations.txtGenericArchived})` : ''
        return this.subrecipe.title + archived || this.translations.txtSubrecipesNew
      }
      return ''
    },
    existingIngredientsIds() {
      if (this.subrecipe) {
        return [this.subrecipe.id]
      }
      return []
    },
    selectedRowsActions() {
      let actions = []
      if (!this.subrecipe.archived && this.currentPermissionsByType.batch_manage) {
        actions.push({
          key: 'ingredient_delete',
          title: this.translations.txtGenericRemove,
          variant: 'outline-danger',
          type: 'button',
          action: (rows) => {
            rows.forEach(row => {
              //if cloudflare will complain about too many requests, we can switch to batch operation or a for of loop
              this.save({
                type: 'ingredient_delete',
                value: {
                  ingredient: row.operation
                }
              })
            })
          }
        })
      }
      return actions
    },
    pageActions() {
      return [
        { key: 9, title: this.translations.txtSubrecipeGetRecipePDF, icon: 'wisk-book', variant: 'primary', action: this.emailRecipePDF },
        { key: 10, title: this.translations.txtPOSItemsServingSizes, icon: 'wisk-whiskey-glass', variant: 'primary', action: this.openServingSizes },
        { key: 20, title: this.translations.txtCustomFieldsView, icon: 'wisk-options', variant: 'primary', action: this.openCustomFields },
        { key: 30, hide: !this.availableVenuesForCopy.length, title: this.translations.txtSubrecipesCopyToVenues, icon: 'wisk-multivenue', variant: 'primary', action: this.openCopyToVenuesDialog },
        { key: 40, isDivider: true, hide: !this.currentPermissionsByType.batch_manage },
        {
          key: 50,
          title: this.translations.txtGenericArchive,
          icon: 'wisk-archive',
          variant: 'danger',
          hide: !this.currentPermissionsByType.batch_manage || this.subrecipe.archived,
          action: this.toggleArchived
        },
        {
          key: 60,
          title: this.translations.txtGenericRestore,
          icon: 'wisk-history',
          variant: 'success',
          hide: !this.currentPermissionsByType.batch_manage || !this.subrecipe.archived,
          action: this.toggleArchived
        }
      ]
    },
    ingredients() {
      if (this.subrecipe && Array.isArray(this.subrecipe.ingredients)) {
        return this.subrecipe.ingredients.map(i => merge({}, i))
      }
      return []
    }
  },
  methods: {
    ...mapActions(['setGlobalAction', 'setSubrecipe']),
    setValidState(errorCount) {
      this.valid = !errorCount
    },
    getPermissionsByVenueIdByType(venueId) {
      return get(this.user, 'god_mode') ? this.currentPermissionsByType || {} : (this.permissionsByVenueIdByType || {})[venueId] || {}
    },
    openCopyToVenuesDialog() {
      this.copyToVenuesDialogVisible = true
    },
    emailRecipePDF() {
      if (this.subrecipe && this.subrecipe.id) {
        api.emailPdf('recipe-book/pdf',
          // `${this.translations.translate('tplSubrecipeRecipePDFFor', { '{a}': this.subrecipe.title })}.pdf`,
          {
            subrecipe_ids: [this.subrecipe.id]
          })
      }
    },
    openCustomFields() {
      this.setGlobalAction({ type: 'customFields', action: { target: 'subrecipe' } })
    },
    openServingSizes() {
      this.setGlobalAction({ type: 'servingSizes', action: {} })
    },
    editSubrecipeImage() {
      if (this.currentPermissionsByType.batch_manage) {
        this.setGlobalAction({
          type: 'fileUpload',
          action: {
            target: this.subrecipe,
            useGoogle: true,
            fileTypesAccept: 'image/*',
            save: value => {
              this.save({ type: 'image', value })
            }
          }
        })
      }
    },
    removeSubrecipeImage() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          message: this.translations.confirmRemoveImages,
          title: this.translations.confirmRemoveTitle,
          callback: () => {
            this.singleFieldSave({ type: 'image_clear' })
          },
        })
      }
    },
    openCustomServingSize(id) {
      let found = this.ingredients.find(i => i.id === id)
      this.selectedCustomServingSizeIngredient = found
    },
    saveCustomServingSize(newMeasurement) {
      let servingSize = merge({}, this.selectedCustomServingSizeIngredient.serving_size),
        ingredient = merge({}, this.selectedCustomServingSizeIngredient.operation),
        from = { ingredient, serving_size: this.selectedCustomServingSizeIngredient.serving_size }

      delete servingSize.quantity
      delete servingSize.unit_of_measurement
      delete servingSize.id

      servingSize.measurement = newMeasurement
      servingSize.type = 'custom'

      let operation = {
        type: 'ingredient_update',
        value: {
          ingredient,
          serving_size: servingSize
        },
        from
      }

      this.singleFieldSave(operation)
      this.selectedCustomServingSizeIngredient = null
    },
    createColumns() {
      if (this.configDataLoaded) {
        this.itemsGridColumns = getItemsGridColumns({
          translations: this.translations,
          servingSizes: this.servingSizes,
          save: this.save,
          allergens: this.venue.venue_type === 'food' ? this.allergens : null,
          currentPermissionsByType: this.currentPermissionsByType,
          disabled: (this.subrecipe && this.subrecipe.archived) || !this.currentPermissionsByType.batch_manage,
          openCustomServingSize: this.openCustomServingSize
        })
      }
    },
    addIngredient(operation) {
      this.save(operation).then(() => {
        setTimeout(() => {
          let element = document.getElementById('pos-item-add-ingredient-autocomplete')

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
          }
        }, 0)
      })
    },
    save(operation) {
      return new Promise((resolve, reject) => {
        if (this.subrecipe) {
          this.loading = true

          this.setSubrecipe({ id: this.subrecipe.id, operation: operation || this.operations })
            .then(result => {
              this.loading = false
              if (this.editAction.onChange) {
                this.editAction.onChange(prepareSubrecipe(result, this.$store.state))
              }
              if (!this.subrecipe.id) {
                this.setGlobalAction({ type: 'subrecipeEdit', action: { id: result.id, resetBeforeOpen: true } })
              }
              resolve(result)
            })
            .catch(() => {
              this.loading = false
              reject()
            })
        } else {
          reject()
        }
      })
    },
    singleFieldSave(operation) {
      if (this.subrecipe && this.subrecipe.id) {
        this.save(operation).then(result => {
          if (operation && operation.type === 'used_as_inventory_item' && operation.value) {
            this.setGlobalAction({ type: 'itemEdit', action: { item_id: result.inventory_item_id } })
          }
        })
      }
    },
    toggleArchived() {
      if (this.$refs.confirmDialog) {
        this.$refs.confirmDialog.confirm({
          callback: () => {
            this.operations = [
              {
                type: 'archive',
                value: !this.subrecipe.archived
              }
            ]
            this.save()
          },
          message: this.subrecipe.archived ? this.translations.confirmRestoreText : this.translations.confirmArchiveText,
          title: this.subrecipe.archived ? this.translations.confirmRestoreTitle : this.translations.confirmArchiveTitle
        })
      }
    },
    prepareSubrecipe() {
      if (this.editAction && this.editAction.id) {
        this.subrecipe = prepareSubrecipe(this.subrecipesById[this.editAction.id], this.$store.state)
      }
    }
  },
  mounted() {
    this.createColumns()

    let siblingVenues = this.user.user_venues.filter(uv => uv.venue.id !== this.venue.id && uv.venue.parent_venue_id === this.venue.parent_venue_id && !uv.venue.use_parent_pos_items).map(uv => uv.venue)
    this.availableVenuesForCopy = siblingVenues.filter(v => this.getPermissionsByVenueIdByType(v.id).batch_manage)
  },
  watch: {
    firestoreInitialLoadComplete: 'prepareSubrecipe',
    subrecipesById: 'prepareSubrecipe',
    bottlesById: 'prepareSubrecipe',
    subrecipeCostById: 'prepareSubrecipe',
    editAction: {
      immediate: true,
      handler() {
        if (this.editAction) {
          this.subrecipe = merge({}, newSubrecipe, this.editAction)

          this.prepareSubrecipe()
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
