<template>
  <v-dropdown :triggers="['click']" :distance="5" @show="scrollToBottom" class="text-center" :container="`#${containerDomId}`">
    <b-button class="bt-needs-attention p-0 m-0 position-relative" size="x-sm" variant="link" :class="{ 'needs_review': lineItem.needs_review }" v-tooltip="mainButtonTooltip">
      <icon :scale="0.8" name="wisk-chat" class="me-1 align-self-md-center"></icon>
      <span v-if="!iconOnly">
        {{ translations.txtScannedInvoicesMissingInformation }}
      </span>

      <b-badge v-if="lineItem?.review_conversation?.length" :variant="lineItem.needs_review ? 'danger' : 'success'" size="sm" class=""
        style="position: absolute; top: -5px; right: -10px; padding: 4px 4px; line-height: 5px;">
        {{ lineItem.review_conversation.length }}
      </b-badge>
    </b-button>

    <template #popper>
      <b-container fluid class="p-2" style="overflow: hidden;">
        <!-- header will be here later -->
        <b-row class="mb-3" v-if="!disabled && !messagesGrouped.length">
          <b-col cols="12">
            <b>{{ translations.txtDraftInvoiceChatConversationStart }}</b>
            <br />
            <small class="text-muted" style="margin-top: -3px; display: block;"> {{ translations.txtDraftInvoiceChatConversationStartInfo }} </small>
          </b-col>
        </b-row>
        <b-row class="mb-3" v-if="!disabled && messagesGrouped.length /*&& lineItem.needs_review*/">
          <b-col cols="8" class="pe-1">
            <b>{{ translations.txtDraftInvoiceChatConversationTitle }}</b>
          </b-col>

          <b-col cols="4" class="ps-0">
            <b-button v-if="lineItem.needs_review" variant="link" class="p-0 float-end" @click="markDone" v-tooltip="translations.txtDraftInvoiceChatConversationMarkDoneInfo">
              <span> {{ translations.txtDraftInvoiceChatConversationMarkDone }} </span>
              <icon class="ms-2" name="wisk-check" :scale="0.9" />
            </b-button>

            <b-alert v-if="!lineItem.needs_review" variant="success" show class="mb-0 p-0 text-center">
              <span>
                {{ translations.txtGenericResolved }}
              </span>
              <icon name="wisk-check" :scale="0.7" class="ms-2" />
            </b-alert>
          </b-col>
        </b-row>

        <div style="max-height: 350px; overflow-y: auto; overflow-x: hidden; padding: 10px;">
          <b-row v-for="messageGroup in messagesGrouped" :key="messageGroup.key" class="mb-3">

            <!-- Display User Name if present -->
            <b-col v-if="messageGroup?.user?.name" cols="12" class="text-muted" style="margin-bottom: -10px;" :style="{ textAlign: messageGroup.side }">
              <b style="font-size: 14px;">{{ messageGroup.user.name }}</b>
            </b-col>

            <!-- Display Time Ago -->
            <b-col cols="12" class="text-muted mt-1" :style="{ textAlign: messageGroup.side }">
              <span>{{ messageGroup.timeAgo }}</span>
            </b-col>

            <!-- Loop through messages -->
            <b-col v-for="message in messageGroup.messages" :key="message.id" :cols="12" :class="['ps-3', 'my-2']" v-tooltip="message.dateFormatted">
              <div :class="`chat-bubble-${messageGroup.side}`" >
                <p class="draft-invoice-line-message m-0">{{ message.message }}</p>
              </div>
            </b-col>

          </b-row>

          <!-- Scroll target -->
          <div :id="`draft-invoice-line-messages-scroll-target-${lineItem.id}`"></div>
        </div>

        <b-row v-if="!disabled" class="mt-2 pb-2" no-gutters>
          <b-col cols="10" class="pe-1" v-tooltip="keyboarSubmitShortcutTooltip">
            <wiskInput size="sm" infoTooltipKey="0bde0fec-587b-4130-8214-8b31fd5cc6ac" :label="messagesGrouped.length ? translations.txtDraftInvoiceChatReply : translations.txtDraftInvoiceChatMessageAdd" multiline
              v-model="newMessage" autoHeightTextArea @enter="() => { lineItem.needs_review ? addMessageMarkDone() : addMessage() }"/>
          </b-col>
          <b-col cols="2" :class="{ 'pt-1' : !!messagesGrouped.length }">
            <b-button v-if="!lineItem.needs_review" variant="link" class="py-2 px-2 float-end w-100" v-tooltip="translations.txtDraftInvoiceChatSend" @click="addMessage" :disabled="!newMessage">
              <icon name="wisk-send" :scale="1.7" />
            </b-button>

            <b-dropdown v-if="lineItem.needs_review" :disabled="!newMessage" variant="link" size="md" toggleClass="m-0 px-1" menuClass="border-0 min-width-0">
              <template v-slot:main-btn>
                <b-button type="button" variant="link" @click="addMessageMarkDone" class="px-1" v-tooltip="translations.txtDraftInvoiceChatSendDone" :disabled="!newMessage">
                  <icon name="wisk-send-done" :scale="1.4" class="text-primary"/>
                </b-button>
              </template>
              <b-dropdown-item @click="addMessage" v-tooltip="translations.txtDraftInvoiceChatSend">
                <icon name="wisk-send" :scale="1.4" class="text-primary" />
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
        <wiskLoading :loading="loading" />
      </b-container>
    </template>
  </v-dropdown>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { hideAllPoppers } from 'floating-vue'
import { formatDate, guid } from '@/modules/utils'
// import testMessages from './testData'

// console.log('testMessages', testMessages)

export default {
  name: 'DraftInvoiceLineReviewMessages',
  emits: ['operations'],
  components: {},
  props: {
    disabled: Boolean,
    lineItem: Object,
    containerDomId: String,
    iconOnly: Boolean
  },
  data() {
    return {
      loading: false,
      newMessage: '',
      formatDate
    }
  },
  computed: {
    ...mapState(['translations', 'deviceInfo']),
    ...mapGetters([]),
    keyboarSubmitShortcutTooltip() {
      let shortcut = 'Ctrl + Enter'

      if (this.deviceInfo?.os?.name === 'Mac OS') {
        shortcut = 'Cmd + Enter'
      }

      return this.translations.translate('tplDraftInvoiceChatSendMessageTooltip', { '{a}': shortcut })
    },
    mainButtonTooltip() {
      if (!this.lineItem?.needs_review) {
        return this.translations[this.lineItem?.review_conversation?.length ? 'txtDraftInvoiceChatConversationTooltipRestart' : 'txtDraftInvoiceChatConversationTooltip']
      }

      let translationKey = this.iconOnly ? 'tplDraftInvoiceChatConversationTooltipShort' : 'tplDraftInvoiceChatConversationTooltip',
        lastMessage = this.lineItem?.review_conversation?.length ? this.lineItem.review_conversation[this.lineItem.review_conversation.length - 1] : null

      return this.translations.translate(translationKey, { '{a}': this.lineItem?.review_conversation?.length, '{b}': formatDate(lastMessage?.date) })
    },
    messagesGrouped() {
      let messages = /*testMessages,//*/this.lineItem.review_conversation || [],
        result = []

      // Sort messages by date in ascending order
      messages.sort((a, b) => new Date(a.date) - new Date(b.date))

      let currentUser = null,
        currentTimeAgo = null,
        side = null

      messages.forEach(m => {
        let message = { ...m, timeAgo: formatDate(m.date, { toRelative: true }), dateFormatted: formatDate(m.date) }

        // Check if the user has changed
        if (!currentUser || currentUser.id !== message.user.id) {
          currentUser = { id: message.user.id, name: message.user.name }
          currentTimeAgo = message.timeAgo
          side = side === 'left' ? 'right' : 'left'

          // Add user entry to the result
          result.push({
            user: currentUser,
            timeAgo: currentTimeAgo,
            messages: [message],
            key: guid(),
            side
          })
        } else if (currentTimeAgo !== message.timeAgo) { // Check if the timeAgo has changed for the same user
          currentTimeAgo = message.timeAgo

          // Add a new timeAgo entry for the same user
          result.push({
            user: null, // No need to repeat the user
            timeAgo: currentTimeAgo,
            messages: [message],
            key: guid(),
            side
          })
        } else {
          // Add message to the existing entry
          result[result.length - 1].messages.push(message)
        }
      })

      console.log('messages', messages)
      console.log('result', result)
      return result
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    addMessage() {
      if (this.newMessage) {
        this.$emit('operations', [{ type: 'needs_review', value: true }, { type: 'review_message', value: this.newMessage }])
        this.newMessage = ''
      }
    },
    addMessageMarkDone() {
      if (this.newMessage) {
        this.$emit('operations', [{ type: 'needs_review', value: false }, { type: 'review_message', value: this.newMessage }])
        this.newMessage = ''
      }
    },
    markDone() {
      this.$emit('operations', [{ type: 'needs_review', value: false }])

      setTimeout(() => {
        hideAllPoppers()
      }, 0)
    },
    scrollToBottom() {
      setTimeout(() => {
        let target = document.getElementById(`draft-invoice-line-messages-scroll-target-${this.lineItem.id}`)

        if (target) {
          target.scrollIntoView({ behavior: 'smooth' })
        }
      }, 0)
    }
  },
  watch: {
    lineItem() {
      this.scrollToBottom()
    }
  }
}
</script>

<style lang="scss">
.bt-needs-attention {

  &.needs_review {
    color: var(--danger);
    visibility: visible !important;
  }
}

.chat-bubble-left {
  background-color: /*var(--bs-secondary-bg-subtle);//*/#f1f0f0; //variables we have in project but these colors look better
  border-radius: 15px;
  padding: 10px;
  margin-right: auto;
  max-width: 75%;
}

.chat-bubble-right {
  background-color: /*var(--bs-primary-bg-subtle);//*/#d1e7fd; //variables we have in project but these colors look better
  border-radius: 15px;
  padding: 10px;
  margin-left: auto;
  max-width: 75%;
}

.draft-invoice-line-message {
  font-size: 14px;
  white-space: pre-line;
}

.min-width-0 {
  min-width: 0;
}
</style>
