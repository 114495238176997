<template>
  <b-container fluid class="px-0">
    <wiskItemsGrid class="px-0" :gridStyle="gridStyle" :columns="columns" :items="items" :options="options" :parentGridName="parentGridName" :header="{}"
      @gridApi="gridApiAvailable" :groupCheckboxSelection="false" trackBy="change_id" v-bind="$attrs"
      :defaultFilter="{ predicate: item => !item.grouped_to }">
      <template v-slot:additional-controls>
        <slot></slot>

        <wiskInput inputType="checkbox" class="pb-2" v-model="showUnchangedCost" :label="translations.txtCostChangesShowUnchangedCost" />
      </template>
    </wiskItemsGrid>

    <loading :loading="loading" />
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import merge from 'lodash.merge'
import isEmpty from 'lodash.isempty'
import api from '@/api'
import { prepareVariantIdAsItem } from '@/modules/utils'
import wiskItemsGrid from '@/components/grids/WiskItemsGrid'
import loading from '@/components/common/WiskLoading'
import { gridOptions, getItemsGridColumns } from './gridOptions'

export default {
  name: 'CostChangesGrid',
  emits: ['gridApi', 'refresh'],
  components: { wiskItemsGrid, loading },
  props: {
    gridStyle: {
      type: Object,
      default: () => ({ height: 'calc(100vh - 120px)' })
    },
    selectedRange: { type: Array, default: () => ([]) },
    movementId: Number,
    parentGridName: { type: String, required: true },
    enableMore: { type: Boolean, default: false },
    exportable: Boolean // being used in printable dashboard page
  },
  data() {
    return {
      columns: {},
      loading: false,
      items: [],
      selectedFilter: 'all',
      costChangesItems: [],
      showUnchangedCost: false
    }
  },
  computed: {
    ...mapState(['user', 'bottlesById', 'translations', 'itemPricesById', 'currentPermissionsByType', 'distributorsById', 'globalActions']),
    options() {
      return merge({}, gridOptions)
    }
  },
  methods: {
    ...mapActions(['setGlobalAction']),
    gridApiAvailable(agGridApi) {
      this.$emit('gridApi', agGridApi)
    },
    setItems() {
      this.items = []
      if (this.bottlesById && !isEmpty(this.bottlesById) && this.costChangesItems?.length) {
        this.items = this.costChangesItems.filter(costChangeItem => this.showUnchangedCost || costChangeItem.change_per_unit)
          .map(costChangeItem =>
            merge(
              {},
              prepareVariantIdAsItem(costChangeItem.item_distributor_id, this.$store.state),
              { costChangeItem, change_id: `${costChangeItem.movement_id}_${costChangeItem.item_distributor_id}` },
            )
          )
      }
      this.loading = false
    },
    getData() {
      if (this.movementId) {
        this.loading = true
        api
          .movementCostChanges(this.movementId)
          .then(data => {
            this.costChangesItems = data
            this.setItems()
          })
          .catch(() => {
            this.loading = false
          })
      } else if (this.selectedRange && this.selectedRange[0] && this.selectedRange[1]) {
        this.loading = true
        const token = this.$route.query.token,
          venueId = this.$route.query.vid
        api
          .costChanges({
            interval: {
              start: this.selectedRange[0] && this.selectedRange[0].toISOString(),
              end: this.selectedRange[1] && this.selectedRange[1].toISOString()
            }
          }, token, venueId)
          .then(data => {
            this.costChangesItems = data
            this.setItems()
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  },
  created() {
    this.columns = getItemsGridColumns({
      translations: this.translations,
      distributorsById: this.distributorsById,
      setGlobalAction: this.setGlobalAction,
      enableMore: this.enableMore
    })
  },
  watch: {
    selectedRange: {
      handler: 'getData',
      immediate: true,
      deep: true
    },
    showUnchangedCost: 'setItems',
    globalActions(next) {
      if (!next.movementEdit || (next.movementEdit && !next.movementEdit.length)) {
        this.getData()
      }
    }
  }
}
</script>

<style lang="scss"></style>
